<template>
  <div class="user">
    <div class="container">
      <div class="form_info">
        <el-form ref="form" :model="queryInfo" label-width="80px">
          <div class="form">
            <div class="comble_txt">
              <el-form-item label="所在地区:" prop="">
                <el-select
                  v-model="queryInfo.province"
                  placeholder="请选择省份"
                  @change="citychange"
                >
                  <el-option :value="''" label="全部"></el-option>
                  <el-option
                    v-for="item in operatingList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select
                  v-model="queryInfo.city"
                  placeholder="请选择地区"
                  @change="districtchange"
                >
                  <el-option :value="''" label="全部"></el-option>
                  <el-option
                    v-for="item in operatingLists"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select v-model="queryInfo.district" placeholder="请选择区域" @change="search">
                  <el-option :value="''" label="全部"></el-option>
                  <el-option
                    v-for="item in areaList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label=" " label-width="0px" style="padding-top: 20px; margin-bottom: 0">
                <div class="input-with">
                  <div style="display: flex">
                    <el-button type="primary" @click="toPage('addenterprise', {})">导入</el-button>
                    <el-button plain @click="setMember">设置合作伙伴</el-button>
                    <el-button plain @click="zcMember">设置智参中心</el-button>
                    <!-- <el-button plain>批量导入</el-button> -->
                    <div style="margin-left: 10px; display: none">
                      <el-upload
                        class="upload-demo"
                        action
                        accept=".xls, .xlsx"
                        :before-upload="beforeUpload"
                        :multiple="false"
                        :http-request="uploadSuccess"
                      >
                        <el-button type="primary" plain>批量导入</el-button>
                      </el-upload>
                    </div>
                  </div>
                  <div>
                    <el-input
                      placeholder="请输入企业名称"
                      v-model="queryInfo.queryConditions"
                      class="input-with-select"
                    >
                      <el-button class="seach" slot="append" @click="changeSearchangeSearch"
                        >搜索</el-button
                      >
                    </el-input>
                  </div>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <div
        class="tab_txt"
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
      >
        <div style="display: flex; align-items: center; padding-bottom: 10px">
          <div style="padding-right: 50px">
            <span>当前条件下共查出{{ total }}家企业</span>
          </div>
          <div
            v-for="(item, index) in orderList"
            :key="index"
            style="display: flex; align-items: center; margin-right: 30px"
          >
            {{ item.name }}
            <div class="main">
              <span
                class="arrUp"
                @click="clickUp1(item, index)"
                :style="{ 'border-bottom-color': item.color1 }"
              ></span>
              <span
                class="arrDown"
                @click="clickDown1(item, index)"
                :style="{ 'border-top-color': item.color2 }"
              ></span>
            </div>
          </div>
        </div>
        <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
          <div class="item_checkbox">
            <el-checkbox
              @change="latechange($event, item)"
              v-model="item.checked1"
              label=""
            ></el-checkbox>
          </div>
          <div class="storeCenter_item_top">
            <div class="storeCenter_item_top_left">
              <img v-if="item.companyLogo" :src="item.companyLogo" alt="" />
              <img v-else src="../../assets/image/Group 3237.png" alt="" />
              <div class="storeCenter_item_top_left_flex">
                <div style="display: flex; align-items: center">
                  <h5 style="display: inline-block; margin: 0; font-size: 20px">
                    {{ item.companyFullName }}
                  </h5>
                  <span v-if="item.zcName" style="margin-left: 15px" class="zcname">{{
                    item.zcName
                  }}</span>
                  <span v-if="item.partnerName" class="partnerName">{{ item.partnerName }}</span>
                  <span class="partnerNames" v-if="item.reportingStatus == 1"
                    >{{ item.name }}报备中...</span
                  >
                </div>
                <div class="storeCenter_item_top_left_flex_tag">
                  <span v-if="item.labelList" style="display: flex">
                    <span v-for="(o, index) in item.labelList.slice(0, 3)" :key="index">
                      <span v-if="index < 3" class="office">{{ o }}</span>
                    </span>
                  </span>
                  <el-popover placement="right" width="430" trigger="hover">
                    <div
                      class="hidden_label_box"
                      style="
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-content: center;
                        flex-wrap: wrap;
                      "
                    >
                      <span
                        class="office"
                        style="
                          border: 1px solid #559eff;
                          color: #4e93fb;
                          box-sizing: border-box;
                          padding: 1px 6px;
                          display: block;
                          font-size: 14px;
                          font-weight: 400;
                          border-radius: 4px;
                          margin-right: 10px;
                          margin-bottom: 10px;
                        "
                        v-for="(o, indexs) in item.labelList"
                        :key="indexs"
                        >{{ o }}</span
                      >
                    </div>
                    <span
                      class="office"
                      slot="reference"
                      v-if="item.labelList && item.labelList.length >= 3"
                      >{{ item.labelList.length }}+</span
                    >
                  </el-popover>
                </div>
                <div class="address">
                  <span style="padding-left: 0px"> {{ item.legalPerson }}</span>
                  <span> {{ item.companyPhone }}</span>
                  <span> {{ item.province }}-{{ item.city }}</span>
                  <!-- <div style="color: #ff7878; display: flex" v-if="item.userApplyCompanyStatus">
                    <i class="el-icon-warning"></i>
                    <span>
                      该企业有用户申请企业会员
                    </span>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="storeCenter_item_top_right">
              <div class="account_cycle" @click="beforeAccountDialog(item.id)">
                <!-- <img
                  src="../../assets/image/Group-1.png"
                  alt=""
                  style="width: 16px; height: 16px; margin-right;: 10px;vertical-align: top;"
                />
                账号周期：{{ item.beginTime }}至{{ item.deadline }}
                <span v-if="item.prompt" style="color: #fd702b; width: 80px; display: inline-block">
                  <img
                    src="../../assets/image/Group.png"
                    alt=""
                    style="width: 16px; height: 16px; margin-right;: 10px;vertical-align: top;"
                  />即将到期</span
                >
                <span v-else style="width: 80px; display: inline-block"></span> -->
              </div>
              <!-- <el-button class="buttom_right" type="primary" @click="associatedClick(item)"
                >关联项目
                <span>({{ item.projectsNumber }})</span>
              </el-button> -->
              <div style="float: left; margin-left: 37%">
                <el-switch
                  @change="updateWechatGroupStatus(item)"
                  style="margin-right: 20px"
                  v-model="item.establishWechatGroup"
                  :active-text="item.establishWechatGroup ? '已建群' : '未建群'"
                >
                </el-switch>
              </div>

              <div style="float: right">
                <el-switch
                  @change="updateWeeklyNewspaperStatus(item)"
                  style="margin-right: 20px"
                  v-model="item.weeklyNewspaperStatus"
                  :active-text="item.weeklyNewspaperStatus ? '已开启周报' : '未开启周报'"
                >
                </el-switch>
              </div>
              <div class="operation_area">
                <el-button type="primary" @click="toPage('accountManagement', item)"
                  >企业用户({{ item.userApplyCompanyNum }})</el-button
                >
                <!-- <el-button type="info" plain @click="EnterpriseContent(item)"
                  >运营内容({{ item.pushNum }})</el-button
                > -->
                <el-button type="info" @click="clcikWeekly(item)" plain
                  >智参周报({{ item.tabloidNum }})</el-button
                >
                <!-- <el-button type="info" @click="clciklabel(item)" plain>企业标签</el-button> -->
                <span class="edit" @click="toPage('addenterprise', item)">编辑</span>
                <!-- <span class="frozen" @click="delcompany(item)">冻结</span> -->
              </div>
            </div>
            <div class="Relevancy" v-if="item.enterpriseCategory == '传统企业'">
              <span>{{ item.enterpriseCategory }}</span>
            </div>
            <div class="Relevancyblue" v-else-if="item.enterpriseCategory != null">
              <span>{{ item.enterpriseCategory }}</span>
            </div>
          </div>
        </div>
        <el-dialog
          title="批量设置合作伙伴"
          :visible.sync="dialogVisible"
          width="467px"
          class="enterprise_dialog"
        >
          <el-form :model="param" label-width="120px">
            <el-form-item label="关联合作伙伴" prop="">
              <el-autocomplete
                v-model="param.keyWord"
                :fetch-suggestions="
                  (res, cb) => {
                    return querySearchAsyncapts2(res, cb)
                  }
                "
                placeholder="请搜索合作伙伴"
                @select="
                  (res) => {
                    return handleSelectcapts2(res)
                  }
                "
              >
                <template slot-scope="{ item }">
                  <div class="name">
                    <img
                      :src="item.coverImage"
                      style="border-radius: 28px"
                      width="28px"
                      height="28px"
                      alt=""
                    />
                    <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                      item.partnerName
                    }}</span>
                  </div>
                </template>
              </el-autocomplete>
            </el-form-item>
          </el-form>
          <div style="text-align: center; margin-top: 40px">
            <el-button type="primary" @click="addMember">确 定</el-button>
            <el-button @click="dialogVisible = false">取 消</el-button>
          </div>
        </el-dialog>
        <el-dialog
          title="批量设置智参中心"
          :visible.sync="dialogVisibles"
          width="467px"
          class="enterprise_dialog"
        >
          <el-form :model="param" label-width="120px">
            <el-form-item label="关联智参中心" prop="">
              <el-autocomplete
                v-model="params.keyWord"
                :fetch-suggestions="
                  (res, cb) => {
                    return querySearchAsyncapts1(res, cb)
                  }
                "
                placeholder="请搜索智参中心"
                @select="
                  (res) => {
                    return handleSelectcapts1(res)
                  }
                "
              >
                <template slot-scope="{ item }">
                  <div class="name">
                    <img
                      :src="item.coverImage"
                      style="border-radius: 28px"
                      width="28px"
                      height="28px"
                      alt=""
                    />
                    <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                      item.zcName
                    }}</span>
                  </div>
                </template>
              </el-autocomplete>
            </el-form-item>
          </el-form>
          <div style="text-align: center; margin-top: 40px">
            <el-button type="primary" @click="addMemberzc">确 定</el-button>
            <el-button @click="dialogVisibles = false">取 消</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
    <div class="new_page" v-if="listData.length">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {
  companylist,
  updateCompanyAccountCycle,
  updateWeeklyNewspaperStatus,
  delcompany,
  importCompany,
  getPartners,
  updateCompanyMember,
  updateCompanyZc
} from '@/api/datement'
import { getWisdomGinsengList } from '@/api/channel'
import { getByFatherIdt, getProvince, updateWechatGroupStatus } from '@/api/demand'
import CitySelect from '../../components/CitySelect.vue'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  companyName: null, //企业名称
  city: null, //所在地区
  companyId: null, //企业ID
  projectName: null, //项目名称
  labelName: null, //标签名称
  province: null, //所在省份
  enterpriseCategory: '', //企业类别
  sortParam: '',
  queryConditions: '', //企业类别
  district: '' // 区
})

export default {
  name: 'Userment',
  data() {
    return {
      orderList: [
        {
          name: '按新增时间',
          color1: '',
          color2: '#C6C6C6',
          asc: 2,
          desc: 1
        },
        {
          name: '按订单量',
          color1: '',
          color2: '#C6C6C6',
          asc: 6,
          desc: 5
        },
        // {
        //   name: '按诊断记录',
        //   color1: '',
        //   color2: '#C6C6C6',
        //   asc: 8,
        //   desc: 7
        // },
        {
          name: '按用户',
          color1: '',
          color2: '#C6C6C6',
          asc: 4,
          desc: 3
        }
      ], //排序
      param: {
        keyWord: '',
        partnerChannelId: null
      },
      //合作伙伴查询
      params: {
        keyWord: '',
        wisdomGinsengId: null
      },
      //智参中心查询
      specilist: [],
      specilist1: [],
      dialogVisible: false,
      dialogVisibles: false,
      ids: [],
      idsList: [],
      enterpriseIds: [],
      enterpriseIdsList: [],
      queryInfo: { ...defaultQueryInfo }, //查询企业数据一览
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      operatingList: [], //省
      operatingLists: [], //市
      districtList: [], // 区
      areaList: [],
      bol: false
    }
  },
  created() {
    this.incity()
    // this.search()
    if(this.$route.query.changeNum){
      this.queryInfo.pageNum = Number(localStorage.getItem('paginationCurrentPage'))
      this.search()
    }else{
      this.queryInfo.pageNum = 1
      this.search()
    }
  },
  watch: {
    // '$route.query.pageNum': {
    //   handler(newValue) {
    //     console.log('Query changed:', newValue)
    //     // 调用你的方法来处理数据获取等
    //   },
    //   deep: true, // 深度监听路由对象的变化
    //   immediate: false // 不在初始化时立即调用 handler
    // }
    $route(to, from) {
      // 当路由改变时，这里的代码会被执行
      console.log('路由变化了')
      console.log('当前页面路由:', to)
      console.log('上一个路由:', from)
    }
  },
  methods: {
    //升序排序
    clickUp1(item, index) {
      ;(this.orderList = [
        {
          name: '按新增时间',
          color1: '',
          color2: '#C6C6C6',
          asc: 2,
          desc: 1
        },
        {
          name: '按订单量',
          color1: '',
          color2: '#C6C6C6',
          asc: 6,
          desc: 5
        },
        // {
        //   name: '按诊断记录',
        //   color1: '',
        //   color2: '#C6C6C6',
        //   asc: 8,
        //   desc: 7
        // },
        {
          name: '按用户',
          color1: '',
          color2: '#C6C6C6',
          asc: 4,
          desc: 3
        }
      ]), //排序
        (this.queryInfo.sortParam = item.asc)
      this.search()
      this.orderList[index].color1 = '#0099ff'
      this.orderList[index].color2 = ''
    },
    //降序排序
    clickDown1(item, index) {
      ;(this.orderList = [
        {
          name: '按新增时间',
          color1: '',
          color2: '#C6C6C6',
          asc: 2,
          desc: 1
        },
        {
          name: '按订单量',
          color1: '',
          color2: '#C6C6C6',
          asc: 4,
          desc: 5
        },
        // {
        //   name: '按诊断记录',
        //   color1: '',
        //   color2: '#C6C6C6',
        //   asc: 8,
        //   desc: 7
        // },
        {
          name: '按用户',
          color1: '',
          color2: '#C6C6C6',
          asc: 4,
          desc: 3
        }
      ]), //排序
        (this.queryInfo.sortParam = item.desc)
      this.search()
      this.orderList[index].color1 = ''
      this.orderList[index].color2 = '#0099ff'
    },
    beforeUpload(file) {
      //判断文件格式
      let hz = file.name.split('.')[1]
      if (hz != 'xlsx' && hz != 'xls') {
        this.$alert('只能上传EXCEL文件！')
        return false
      }
    },
    async uploadSuccess(params) {
      const excelFile = new FormData()
      excelFile.append('excelFile', params.file)
      this.loading = true
      const res = await importCompany(excelFile)
      if (res.data.resultCode == 200) {
        this.loading = false
        this.$message({
          type: 'success',
          message: '导入成功!'
        })
        this.search()
      } else {
        this.loading = false
        this.$alert('添加失败!' + res.data.message)
      }
    },

    async querySearchAsyncapts2(queryString, cb) {
      if (this.param.keyWord) {
        const res = await getPartners({
          keyWord: this.param.keyWord
        })
        if (res.data.resultCode == 200) {
          this.specilist = res.data.data
          cb(this.specilist)
        }
      } else {
        cb(this.specilist)
      }
    },

    //选择合作伙伴
    handleSelectcapts2(item) {
      this.param.keyWord = item.partnerName
      this.param.partnerChannelId = item.id
    },
    async querySearchAsyncapts1(queryString, cb) {
      if (this.params.keyWord) {
        const res = await getWisdomGinsengList({
          keyWord: this.params.keyWord
        })
        if (res.data.resultCode == 200) {
          this.specilist1 = res.data.data
          cb(this.specilist1)
        }
      } else {
        cb(this.specilist1)
      }
    },

    //选择智参中心
    handleSelectcapts1(item) {
      this.params.keyWord = item.zcName
      this.params.wisdomGinsengId = item.id
    },
    toPage(type, row) {
      if (type == 'accountManagement') {
        this.$router.push({
          path: '/customer/CorrelateUser',
          query: {
            id: row.id
          }
        })
      } else if (type == 'addenterprise') {
        this.$router.push({
          path: '/customer/addenterprise',
          query: {
            id: row.id,
            headTabActive: 1,
            customerType: 1,
            pageNum: this.queryInfo.pageNum
          }
        })
      }
    },
    //标签
    clciklabel(row) {
      this.$router.push({
        path: '/customer/addenterprise',
        query: {
          id: row.id,
          headTabActive: 2
        }
      })
    },
    //智参周报一览
    clcikWeekly(row) {
      this.$router.push({
        path: '/customer/weekly',
        query: {
          id: row.id
        }
      })
    },
    //内容管理
    EnterpriseContent(row) {
      this.$router.push({
        path: '/customer/EnterpriseContent',
        query: {
          id: row.id
        }
      })
    },

    // 添加合作伙伴
    async addMember() {
      const query = {
        ids: this.ids,
        partnerChannelId: this.param.partnerChannelId
      }
      if (this.ids.length) {
        const { data: res } = await updateCompanyMember(query)
        if (res.resultCode === 200) {
          this.$message.success('设置成功')
          this.search()
          this.dialogVisible = false
        } else {
          this.$message.error(res.message)
        }
      }
    },
    //确认设置智参中心
    async addMemberzc() {
      const query = {
        ids: this.ids,
        wisdomGinsengId: this.params.wisdomGinsengId
      }
      if (this.ids.length) {
        const { data: res } = await updateCompanyZc(query)
        if (res.resultCode === 200) {
          this.$message.success('设置成功')
          this.search()
          this.dialogVisibles = false
        } else {
          this.$message.error(res.message)
        }
      }
    },

    // 设置合作伙伴
    setMember() {
      if (this.ids.length) {
        this.dialogVisible = true
        this.enterpriseIds = [...this.ids]
        this.enterpriseIdsList = this.idsList.map((obj) => ({ ...obj }))
      } else {
        this.$message.warning('请选择企业')
      }
    },
    //设置智参
    zcMember() {
      if (this.ids.length) {
        this.dialogVisibles = true
        this.enterpriseIds = [...this.ids]
        this.enterpriseIdsList = this.idsList.map((obj) => ({ ...obj }))
      } else {
        this.$message.warning('请选择企业')
      }
    },

    //进去城市
    async getByFatherIdt(id) {
      const res = await getByFatherIdt({ provinceCode: id })
      if (res.data.resultCode == 200) {
        this.operatingLists = res.data.data
      }
    },
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.queryInfo.province == o.name) {
          this.queryInfo.city = ''
          if (item == '全部') {
            this.queryInfo.pageNum = 1
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.queryInfo.pageNum = 1
            this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
          }
        }
      })
      this.search()
    },
    districtchange(item) {
      this.operatingLists.forEach((o) => {
        if (this.queryInfo.city == o.name) {
          this.queryInfo.district = ''
          if (item == '全部') {
            this.areaList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
            // console.log(this.areaList, 'this.areaList ')
          } else {
            this.areaList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
      this.search()
    },
    // citychange(item) {
    //   this.operatingList.forEach((o) => {
    //     if (this.queryInfo.province == o.name) {
    //       this.queryInfo.city = ''
    //       if (item == '全部') {
    //         this.queryInfo.pageNum = 1
    //         this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
    //       } else {
    //         this.queryInfo.pageNum = 1
    //         this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
    //       }
    //     }
    //   })
    //   this.search()
    // },
    // townchang(item) {
    //   this.operatingLists.forEach((itm) => {
    //     console.log(itm)
    //   })
    // },
    async search() {
      this.loading = true
      const { data: res } = await companylist(this.queryInfo)
      if (res.resultCode == 200) {
        this.listData = res.data.list

        this.total = res.data.total
        this.loading = false
        this.listData.forEach((item) => {
          this.$set(item, 'checked1', false)
        })
      } else {
        this.$message.error(res.message)
      }
    },

    //删除企业
    delcompany(row) {
      const ids = row.id
      this.$alert('您是否确认冻结？')
        .then(function () {
          return delcompany(ids)
        })
        .then(() => {
          this.search()
          this.$message.success('冻结成功')
        })
        .catch(() => {})
    },
    //跳转新增
    handleAdd() {
      this.$router.push({
        path: '/datament/addenterprise'
      })
    },
    //编辑企业
    editclick(item) {
      this.$router.push({
        path: '/customer/addenterprise',
        query: { id: item.id }
      })
    },
    associatedClick(item) {
      this.$router.push({
        name: 'associated',
        query: { id: item.id }
      })
    },
    //关联用户
    UserClick(item) {
      this.$router.push({
        name: 'CorrelateUser',
        query: {
          id: item.id,
          secretaryGeneralName: item.secretaryGeneralName,
          chamberCommerceName: item.chamberCommerceName
        }
      })
    },
    latechange(bol, row) {
      const index = this.ids.indexOf(row.id)
      if (index == -1) {
        this.ids.push(row.id)
        this.idsList.push(row)
      } else {
        this.ids.splice(index, 1)
        this.idsList.splice(index, 1)
      }
    },
    //启动关闭
    async updateWeeklyNewspaperStatus(item) {
      const query = {
        id: item.id,
        value: item.weeklyNewspaperStatus
      }
      const { data: res } = await updateWeeklyNewspaperStatus(query)
      if (res.resultCode === 200) {
        this.search()
        this.$message.success('操作成功')
      }
    },
    //建群开关
    async updateWechatGroupStatus(item) {
      const query = {
        companyId: item.id,
        value: item.establishWechatGroup
      }
      const { data: res } = await updateWechatGroupStatus(query)
      if (res.resultCode === 200) {
        if (res.data == 510) {
          this.$message.warning(res.message)
        } else {
          this.search()
          this.$message.success('操作成功')
        }
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      localStorage.setItem('paginationCurrentPage', val)
      this.queryInfo.pageNum = val
      this.search()
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-upload-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
}
::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  transform: rotate(45deg) scaleY(0);
  width: 4px;
  transition: transform 0.15s ease-in 50ms;
  transform-origin: center;
}
::v-deep .enterprise_dialog .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 300px;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}

::v-deep .storeCenter_item_top_right .el-button {
  width: 150px;
}

.zcname {
  background: rgba(78, 147, 251, 0.1);
  border: 0.5px solid #4e93fb;
  border-radius: 2px;
  font-size: 14px;
  color: #4e93fb;
  display: inline-block;
  padding: 2px 6px;
}
.partnerName {
  background: rgba(255, 125, 24, 0.1);
  border: 0.5px solid #ff7d18;
  border-radius: 2px;
  font-size: 14px;
  color: #ff7d18;
  display: inline-block;
  padding: 2px 6px;
  margin-left: 10px;
}
.partnerNames {
  background: rgba(255, 125, 24, 0.1);
  border-radius: 2px;
  font-size: 14px;
  color: #ff7d18;
  display: inline-block;
  padding: 2px 6px;
  margin-left: 10px;
}
.label_list {
  display: flex;
  align-items: center;
  margin-top: 8px;
  .label {
    cursor: pointer;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    margin-right: 15px;
    cursor: pointer;
  }
  .acitve_label {
    padding: 0 10px;
    cursor: pointer;
    height: 28px;
    color: #146aff;
    background: #cbdeff;
    border-radius: 2px;
  }
}
.Subtag_list {
  padding: 10px;
  min-height: 77px;
  background: #f5f6f7;
  margin-top: 10px;
  display: flex;
  cursor: pointer;
  .label_title {
    width: 70px;
    flex-shrink: 0;
    white-space: nowrap;
    // span {
    //   font-family: 'PingFang SC';
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 12px;
    //   line-height: 17px;
    //   margin-right: 15px;
    //   color: #7c7f8e;
    // }
  }
  .label_box {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    div {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height */
      margin-right: 10px;
      color: #1f212b;
      // margin-bottom: 10px;
      height: 20px;
      padding: 5px 10px;
    }
    white-space: pre-wrap;
    .acitve_label {
      // height: 28px;
      color: #146aff;
      background: #cbdeff;
      border-radius: 2px;
      cursor: pointer;
    }
  }
}
.enterprise_dialog {
  .tip {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
  }
  .enterprise_box {
    .enterprise_item {
      margin-top: 10px;
      width: 387px;
      padding: 10px;
      background: #f4f4f4;
      border-radius: 1px;
      display: flex;
      justify-content: space-between;
      .left {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        /* identical to box height */

        color: #151515;
      }
      .right {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        color: #fd5469;
      }
    }
  }
}
.user {
  .form_info {
    background: #ffffff;
    padding: 20px;
  }

  .container {
    height: calc(100vh - 150px);
    overflow: auto;
    &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  }

  .tab_txt {
    margin-top: 20px;
    padding: 0px;
    .main {
      display: flex;
      flex-direction: column;
    }

    .arrUp {
      width: 0;
      height: 0;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-bottom: 8px solid #c6c6c6;
      cursor: pointer;
    }

    .arrDown {
      width: 0;
      height: 0;
      margin-top: 2px;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-top: 8px solid #c6c6c6;
      cursor: pointer;
    }
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;

    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

  .storeCenter_item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    .item_checkbox {
      line-height: 36px;
      margin: 0px 10px;
    }
    .Rellevancy {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #ff7d18;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .Relevancyblue {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #146aff;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .storeCenter_item_top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-bottom: 14px;
      position: relative;
      background: #ffffff;
      padding: 16px 20px 10px 10px;
      .storeCenter_item_top_left {
        display: flex;
        flex-direction: row;

        img {
          width: 90px;
          height: 90px;
          border-radius: 4px;
          margin-right: 14px;
        }

        .storeCenter_item_top_left_flex {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .img_commer {
            display: flex;
            border: 1px solid #146aff;
            border-radius: 39px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #146aff;
            }
            .color_img {
              background: #146aff;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }
          .img_commers {
            display: flex;
            border: 1px solid #ff9c00;
            border-radius: 39px;
            margin: 0px 10px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #ff9c00;
            }
            .color_img {
              background: #ff9c00;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }

          h4 {
            color: #1f2d3d;
            font-size: 26px;
            font-weight: 500;
            margin: 0;
          }

          .storeCenter_item_top_left_flex_tag {
            width: 100%;
            display: flex;
            align-items: center;
            padding-top: 13px;

            span {
              box-sizing: border-box;
              padding: 1px 6px;
              display: block;
              font-size: 14px;
              font-weight: 400;
              border-radius: 4px;
            }

            .office {
              border: 1px solid #559eff;
              color: #4e93fb;
              cursor: pointer;
            }

            .support {
              border: 1px solid #fd523f;
              color: #fd523f;
              margin-left: 12px;
            }
          }

          .address {
            display: flex;
            align-items: center;
            font-size: 14px;
            span {
              padding: 0px 10px;
            }
          }
        }
      }
      .storeCenter_item_top_right {
        align-items: stretch;
        height: 100%;
        width: 550px;
        // display: flex;
        .account_cycle {
          text-align: right;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;

          /* 灰色字体 */

          color: #969495;
        }
        .operation_area {
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .buttom_right {
            height: 36px;
            line-height: 1px;
          }
          .edit {
            padding-left: 20px;
            color: #4e93fb;
            cursor: pointer;
          }
          .frozen {
            padding-left: 20px;
            color: #fd5469;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
